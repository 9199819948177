



import { Component, Vue, Watch } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import { getUrlKey, checkLoginAndRedirect, getLS } from '@/utils';
import { LoadingPlugin } from 'tdesign-vue';

@Component({})
/** 根组件 */
export default class APP extends Vue {
  isLoad = false;
  /** 钩子函数 */
  public async created() {
    await this.getUserInfo();
  }

  /** 钩子函数 */
  public async mounted() {
    const sessionKey: any = getUrlKey('sessionKey');

    // 获取url sessionKey
    if (sessionKey) {
      window.location.href = `${window.location.origin}/jscoa/#/home/index`;
    }

    const lang: string = Cookies.get('t_c_p_r_language') || 'zh-CN';
    // 国际化 默认中文 CN 中文  EN 英文
    let langVal = 'zh-CN';
    // console.log(lang, "langlang");
    switch (lang) {
      case 'zh-CN':
        langVal = 'zh';
        break;
      case 'en-US':
        langVal = 'en';
        break;
      default:
        Cookies.set('t_c_p_r_language', langVal);
        Cookies.set('_JARVIS.LOCALE_', 'zh-CN');

        langVal = 'zh';
        break;
    }

    this.$i18n.locale = langVal;
    // Cookies.set('_JARVIS.LOCALE_', lang === 'en' ? 'en-US' : 'zh-CN');
    this.$store.commit('setLang', langVal);
  }

  /**
   * 获取用户信息
   */
  public async getUserInfo() {
    const loadingAttachInstance = LoadingPlugin({
      attach: () => this.$refs.content as any,
      showOverlay: true,
      size: '20px',
    });
    try {
      await checkLoginAndRedirect();

      const user: any = getLS('user');
      console.log(user)

      // user.menus = (user.menus || []).filter((item)=>item.code === "antitrust");
      // console.log(user.menus,'tt')
      const [one] = user.menus;
      // console.log(one);

      if (location.pathname === '/' && one) {
        this.$router.replace(one.url);
      }
      //
      // console.log(user, "getUserInfo");
      // Cookies.set('t_c_p_r_language', user.language);
      this.$store.commit('setUserInfo', user);
    } catch (error) {
    } finally {
      this.isLoad = true;
      loadingAttachInstance.hide();
    }
  }
}
