/**
 * 获取url中的参数
 * @create 2019-10-30
 * @author skyinzhang
 * @params name 需要从url中获取的字段名称
 * @describe 获取url中的参数
 */
export const getQueryString = (name: string) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (!!r) return unescape(r[2]);
  return null;
};


/**
 * vue 获取url中的参数
 * @create 2019-10-30
 * @author skyinzhang
 * @params name 需要从url中获取的字段名称
 * @describe 获取url中的参数
 */
export const getUrlKey = (name: string) => {
  const reg: any = new RegExp(`[?|&]${name}=` + '([^&;]+?)(&|#|;|$)');
  const str: any = (reg.exec(location.href) || [, ''])[1].replace(/\+/g, '%20');
  return decodeURIComponent(str) || null;
};


/**
 *  判断是不是http:// 或者 https
 */
export const isHttpsOrHttp = (name: string): boolean => {
  const reg: any = /(http|https):\/\/([\w.]+\/?)\S*/;
  const r = name.match(reg);
  // console.log("---->",Boolean(r),r)
  return !!r;
};
