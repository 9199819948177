import { Noop } from '@/utils';
import { RouteConfig } from 'vue-router';

// export default {
//   path: '/home',
//   name: '主页',
//   component: home,
// };

const childRouter: RouteConfig = {
  path: 'logic',
  name: '报表逻辑处理',
  redirect: '/logic/reportLogicTrantotal',
  component: Noop,
  meta: {
    show: true,
  },
  children: [
    {
      path: 'reportLogicTrantotal',
      name: '本部收入折算&分部',
      component: () => import('@/views/reportLogicTrantotal'),
    },
    {
      path: 'reportlogicJttotal',
      name: '集团报表逻辑处理',
      component: () => import('@/views/reportlogicJttotal'),
    },
  ],
};

export default childRouter;
