const mutations: any = {
  setPhotoFolderPreView: (state: any, payload: any) => (state.photoFolderPreView = payload),
  /** 国际化设置 */
  setLang(state: any, locale: string): void {
    // 国际化
    if (state.locales.includes(locale)) {
      state.locale = locale;
    }
  },
  setSourceData(state: any, sourceData: any): void {
    state.sourceData = sourceData;
  },
  setUserInfo(state: any, userinfo: any): void {
    state.userinfo = userinfo;
  },
  setTableEditStatus(state: any, status: boolean): void {
    state.tableEditStatus = status;
  },
};

export default mutations;
