// import '@utils/jmonitor'; // 暂时关闭监控
import Vue from 'vue';
import ElementUI, { Loading, MessageBox, Message, Notification } from 'element-ui';
import { removeLS } from '@/utils';
import Cookies from 'js-cookie';
import router from '@/router'; // 路由
import store from '@/store'; // 状态处理
import App from '@/views/App.vue'; // 根组件
import i18n from '@/lang'; // 国际化
import Fragment from 'vue-fragment';

import 'normalize.css'; // css样式初始化
import 'nprogress/nprogress.css'; // 加载动作条样式
import '@/assets/svg';
import '@/styles/thirdlib.scss'; // 更改第三方样式
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';

import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/icon.css';

import TDesign from 'tdesign-vue';
// 引入组件库全局样式资源
import 'tdesign-vue/es/style/index.css'; // element ui css

//
// 引入样式
import 'vue-easytable/libs/theme-default/index.css';
// 引入组件库
import VueEasytable, { VeLocale } from 'vue-easytable';

// 引入英文语言包
import zhCN from 'vue-easytable/libs/locale/lang/zh-CN.js';
import enUS from 'vue-easytable/libs/locale/lang/en-US.js';

const lang: string = Cookies.get('t_c_p_r_language') || 'zh-CN';

lang === 'zh-CN' ? VeLocale.use(zhCN) : VeLocale.use(enUS);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

// 清空缓存数据
// removeLS('t_c_p_r_user');
// removeLS('t_c_p_r_menus');
// removeLS('t_c_p_r_log');

// import tdesign from '@tencent/tdesign-vue';
// import '@tencent/tdesign-vue/dist/tdesign.css';

//  Register global directives
// Object.keys(directives).forEach((key: any): any => {
// 	// eslint-disable-next-line no-undef
// 	const directivesVal: any = directives;

// 	Vue.directive(key, directivesVal[key]);
// });

// // Register global filter functions
// Object.keys(filters).forEach((key: any): any => {
// 	const filtersVal: any = filters;

// 	Vue.filter(key, filtersVal[key]);
// });

// tdesgin 注册为全局
Vue.use(TDesign);
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(Fragment.Plugin);

Vue.use(VueEasytable);

window.WEBAPP = new Vue({
  router,
  store,
  i18n,
  render: (h: any): any => h(App),
} as any).$mount('#app');
