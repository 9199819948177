import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { setLS, getLS, removeLS } from '@/utils';
import { urlOmitParams } from '@/utils/common/public';

const API_HOST = process.env.VUE_API_API_HOST || '/';

/**
 *
 * @param method  是请求的方法
 * @param url 接口地址
 * @param params 请求参数
 * @param reqConf 请求配置
 * @returns
 */
const axiosInstantiation = (params: any, reqConf: any, fn?: any): any => {
  // 创建axios实例
  const service: AxiosInstance = axios.create({
    baseURL: API_HOST, // 如果url不是绝对路径，那么会将baseURL和url拼接作为请求的接口地址,用来区分不同环境，
    timeout: 120000, // 请求超时时间 修改为2min
    responseType: reqConf.responseType, // 表示服务器响应的数据类型
  });

  // `transformRequest` 允许在向服务器发送前，修改请求数据
  // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
  // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
  service.interceptors.request.use(
    (config: any) => {
      config.data = params;

      config.headers = {
        ...reqConf.headers,
        /**
         * 自定义表头，后端需要显式配置,否则前端获取不到.
         * 例如 （java）：
         * 	HttpServletResponse response;	// HttpServletResponse实例
         * 	response.setHeader("Access-Control-Expose-Headers", "My-Personal-Website");
         */
        'x-smp-language': Cookies.get('t_c_p_r_language') || '',
        // 'x-smp-language': '',
        'Content-token': getLS('t_c_p_r_token') || '',
      };
      return config;
    },
    (error: any): any => {
      console.log('request >>>>>>>>>  Promise.reject(error) :', Promise.reject(error));
      return {};
    }
  );

  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  service.interceptors.response.use(
    (config: any) => {
      // 从返回header中取token
      if (config.headers['access-token']) {
        setLS('t_c_p_r_token', config.headers['access-token']);
      }
      // 处理返回值回调
      fn(config);
      // const { code, msg } = config.data;
      // if(code !== 200){

      // }
      return config.data;
    },
    (error: any): any => {
      console.log('response >>>>>>>>>  Promise.reject(error):', Promise.reject(error));
      const { response } = error;
      if (response && response.status === 401) {
        removeLS('user');
        // 跳转登录
        location.href = `/api/fjarvisxLogin?redirectUrl=${urlOmitParams('redirectUrl')}`;
      }
      // if (response && response.status === 403) {
      //   location.href = '/noPermission';
      // }

      // if (response && response.status === 404) {
      //   location.href = '/error';
      // }
      //  403 catch 到无权限页面

      // VUE.prototype.$message({
      //   type: "error",
      //   message: `${response.status} ${response?.data?.msg}`
      // });
      if (response && response.status === 500) {
        return {
          model: null,
          code: 500,
        };
      }
      return response?.model || {};
      // return [];
    }
  );

  return service;
};

export default axiosInstantiation;
