/**
 * 获取浏览器可视区域 信息
 * @returns
 */
export const viewerInfo = () => {
  let pageWidth: any = window.innerWidth;
  let pageHeight: any = window.innerHeight;

  if (typeof pageWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      pageWidth = document.documentElement.clientWidth;
      pageHeight = document.documentElement.clientHeight;
    } else {
      pageWidth = document.body.clientWidth;
      pageHeight = document.body.clientHeight;
    }
  }

  return {
    width: `${pageWidth}`,
    height: `${pageHeight}`,
  };
};
