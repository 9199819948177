import { Noop } from '@/utils';
import { RouteConfig } from 'vue-router';

// export default {
//   path: '/home',
//   name: '主页',
//   component: home,
// };

const childRouter: RouteConfig = {
  path: '/report',
  redirect: '/report',
  name: '反垄断财报',
  component: Noop,
  meta: {
    show: true,
  },
  children: [
    {
      path: '',
      name: '反垄断财报',
      component: Noop,
      children: [
        {
          path: '/report/:table',
          name: '反垄断财报Noop',
          component: () => import('@/views/finReport'),
        },
        {
          path: '/r/rpDetail',
          name: 'RP明细报表',
          component: () => import('@/views/finReport/rpDetailReport'),
        },
        {
          path: '/r/exchange',
          name: '折算汇率报表',
          component: () => import('@/views/finReport/exchangeReport'),
        },
      ],
    },
    {
      path: '/e/reportExport',
      name: '反垄断财报导出',
      component: () => import('@/views/finReportExport'),
      // component: () => import('@/views/about'),
    },
  ],
};

export default childRouter;
