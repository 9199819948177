const state: any = {
  photoFolderPreView: false, // 是否为 照片夹-上传预览页
  locales: ['zh', 'en'],
  locale: 'zh',

  //
  sourceData: [],
  //
  userinfo: null,
  tableEditStatus: false, // 跟踪表格编辑状态
};

export default state;
