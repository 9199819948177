const TAG = '[CheckLogin]';
const log = (...args: any[]) => console.log(TAG, ...args);

export const checkLoginAndRedirect = (): Promise<string | null> => new Promise((resolve) => {
  const ajax = new XMLHttpRequest();

  ajax.open('get', '/api/jarvis/user/permission/profile');
  ajax.setRequestHeader('Accept', 'application/json');
  ajax.onreadystatechange = () => {
    const { readyState, status, responseText } = ajax;
    // console.log(ajax);
    if (readyState !== 4) return;
    log('statechange, ', { readyState, status, responseText });

    if (readyState === 4 && status === 200) {
      log('request success!');
      const responseTextData = JSON.parse(responseText).data;
      const responseTextCode = JSON.parse(responseText).code;
      if (responseTextCode == 401) {
        console.error(responseText);
        const currUrl = location.href;

        localStorage.removeItem('user');

        if (!currUrl.match(/redirect_url=[^=]+&?/)) {
          // const nextUrl = `/service/api?redirect_url=${currUrl}`;
          const nextUrl = `/api/fjarvisxLogin?redirectUrl=${currUrl}`;
          log('check failed, and url not have redirect_url qs, so just redirect => ', nextUrl);
          // console.log(nextUrl);
          location.href = nextUrl;
          throw new Error('check failed'); // 抛出异常，中断checkLoginAndRedirect的执行
        } else {
          log('check failed, url have redirect_url param, just ignore!');
        }
        return resolve(null);
      }
      //  tfrp
      const hosts = window.location.host;
      const hrefs = window.location.href;
      if (hosts.search(/tfrp/) != -1) {
        console.log('-- tfrp --');
      } else if (hosts.search(/tfdp/) != -1) {
        // tdfp
        const [one] =  (responseTextData.menus || []).filter(item => item.code === 'antitrust');
        if (one) {
          responseTextData.menus = one.children || [];
        } else {
          responseTextData.menus = [];
        }
      } else {
        // throw new Error()
        console.log(`unknows host ${JSON.stringify(window.location)}`);
      }

      localStorage.setItem('user', JSON.stringify(responseTextData));

      return resolve(ajax.responseText);
    }
    if (status === 401 || status === 500 || status === 0) {
      const currUrl = location.href;

      localStorage.removeItem('user');

      if (!currUrl.match(/redirect_url=[^=]+&?/)) {
        // const nextUrl = `/service/api?redirect_url=${currUrl}`;
        const nextUrl = `/api/fjarvisxLogin?redirectUrl=${currUrl}`;
        log('check failed, and url not have redirect_url qs, so just redirect => ', nextUrl);
        // console.log(nextUrl);
        location.href = nextUrl;
        throw new Error('check failed'); // 抛出异常，中断checkLoginAndRedirect的执行
      } else {
        log('check failed, url have redirect_url param, just ignore!');
      }
      return resolve(null);
    }
    if (status === 404) {
      // console.log('-=0');
      // location.href = `${location.origin}/error`;
      return resolve(null);
    }
    if (status === 403) {
      // TODO: 无权限时, 跳到对应页面
      location.href = `${location.origin}/noPermission`;
    }
    log('check failed');
    resolve(null);
  };
  ajax.send();
});

// (window as any).checkLoginPromise = checkLoginAndRedirect();
