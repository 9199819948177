import { Noop } from '@/utils';
import { RouteConfig } from 'vue-router';

// export default {
//   path: '/home',
//   name: '主页',
//   component: home,
// };

const childRouter: RouteConfig = {
  path: 'apply',
  name: '数据申请',
  redirect: '/apply',
  meta: {
    show: true,
  },
  component: () => import('@/views/dataApply'),
};

export default childRouter;
