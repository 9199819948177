// 整数保留两位 四位小数
export const NumberCheck = (num, limit) => {
  let str = num;
  const len1 = str.substr(0, 1);
  const len2 = str.substr(1, 1);
  // 如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != '.') {
    str = str.substr(1, 1);
  }
  // 第一位不能是.
  if (len1 == '.') {
    str = '';
  }
  // 限制只能输入一个小数点
  if (str.indexOf('.') != -1) {
    const str_ = str.substr(str.indexOf('.') + 1);
    if (str_.indexOf('.') != -1) {
      str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1);
    }
  }
  // 正则替换
  str = str.replace(/[^\d^.]+/g, ''); // 保留数字和小数点
  if (limit === 2) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1'); // 小数点后只能输 2 位
  }
  if (limit === 4) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/, '$1'); // 小数点后只能输 4 位
  }
  return str;
};

/*
 * 判断obj是否为一个整数 整数取整后还是等于自己。利用这个特性来判断是否是整数
 * 或者使用 Number.isInteger()
 */
const isInteger = obj => Math.floor(obj) === obj;
/*
 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
 * @param floatNum {number} 小数
 * @return {object}
 *   {times:100, num: 314}
 */
const toInteger = (floatNum: number) => {
  // 初始化数字与精度 times精度倍数  num转化后的整数
  const ret = { times: 1, num: 0 };
  const isNegative = floatNum < 0; // 是否是小数
  if (isInteger(floatNum)) {
    // 是否是整数
    ret.num = floatNum;
    return ret; // 是整数直接返回
  }
  const strfi = `${floatNum}`; // 转换为字符串
  const dotPos = strfi.indexOf('.');
  const len = strfi.substr(dotPos + 1).length; // 拿到小数点之后的位数
  const times: number = Math.pow(10, len); // 精度倍数
  /* 为什么加0.5?
      前面讲过乘法也会出现精度问题
      假设传入0.16344556此时倍数为100000000
      Math.abs(0.16344556) * 100000000=0.16344556*10000000=1634455.5999999999
      少了0.0000000001
      加上0.5 0.16344556*10000000+0.5=1634456.0999999999 parseInt之后乘法的精度问题得以矫正
    */
  const str = Math.abs(floatNum) * times + 0.5;
  let intNum: number = parseInt(`${str}`, 10);
  ret.times = times;
  if (isNegative) {
    intNum = -intNum;
  }
  ret.num = intNum;
  return ret;
};

/*
 * 核心方法，实现加减乘除运算，确保不丢失精度
 * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
 * @param a {number} 运算数1
 * @param b {number} 运算数2
 */
const operation = (a, b, op) => {
  const o1 = toInteger(a);
  const o2 = toInteger(b);
  const n1 = o1.num; // 3.25+3.153
  const n2 = o2.num;
  const t1 = o1.times;
  const t2 = o2.times;
  const max = t1 > t2 ? t1 : t2;
  let result: any = null;
  switch (op) {
    // 加减需要根据倍数关系来处理
    case 'add':
      if (t1 === t2) {
        // 两个小数倍数相同
        result = n1 + n2;
      } else if (t1 > t2) {
        // o1 小数位 大于 o2
        result = n1 + n2 * (t1 / t2);
      } else {
        // o1小数位小于 o2
        result = n1 * (t2 / t1) + n2;
      }
      return result / max;
    case 'subtract':
      if (t1 === t2) {
        result = n1 - n2;
      } else if (t1 > t2) {
        result = n1 - n2 * (t1 / t2);
      } else {
        result = n1 * (t2 / t1) - n2;
      }
      return result / max;
    case 'multiply':
      // 325*3153/(100*1000) 扩大100倍 ==>缩小100倍
      result = (n1 * n2) / (t1 * t2);
      return result;
    case 'divide':
      // (325/3153)*(1000/100)  缩小100倍 ==>扩大100倍
      result = (n1 / n2) * (t2 / t1);
      return result;
  }
};

// 加减乘除的四个接口
const add = (a: any, b: any) => operation(a, b, 'add');
const subtract = (a, b) => operation(a, b, 'subtract');
const multiply = (a, b) => operation(a, b, 'multiply');
const divide = (a, b) => operation(a, b, 'divide');


// 加减乘除
export const floatObj = () => ({
  add,
  subtract,
  multiply,
  divide,
});

/**
 * 保留小数点 2 位
 * @params value
 * @return
 */
export const returnFloat = (val: any) => {
  let value: any = Math.round(parseFloat(val) * 100) / 100;
  const s = value.toString().split('.');
  if (s.length === 1) {
    value = `${value.toString()}.00`;
    return value;
  }
  if (s.length > 1) {
    if (s[1].length < 2) {
      value = `${value.toString()}0`;
    }
    return value;
  }
};

/**
 *
 * @param val 小数点前格式化为逗号格式，小数点后不加逗号
 */
export const bigNumberFormat = (val: any) => {
  const temp = `${val}`.split('.');
  return temp.length >= 2 ? `${temp[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${temp[1]}` : temp[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
