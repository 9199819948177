






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
/** svg处理 */
export default class ComponentSVG extends Vue {
  @Prop({ default: '', required: true })
  svgName!: string;

  @Prop({ default: '' })
  className!: string;

  /**  获取名 */
  private get iconName(): any {
    return `#icon-${this.svgName}`;
  }

  /** 获取类名 */
  private get svgClass(): string {
    let svgStr = '';

    if (this.className) {
      svgStr = `svg-icon ${this.className}`;
    } else {
      svgStr = 'svg-icon';
    }
    return svgStr;
  }
}
