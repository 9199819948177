// import locale from 'element-ui/lib/locale';
// import langEn from 'element-ui/lib/locale/lang/en';
// locale.use(langEn);

export const en: any = {
  noop: 'noop', // 过滤无需命名路由
  '腾讯公司 S2-财经线/财经IT管理部': 'Tencent S2-Finance Line/Financial IT Management Department',
  日期: 'Date',
  姓名: 'Name',
  地址: 'Address',
  首页: 'Home',
  系统设置: 'System Settings',
  反垄断系统设置: 'System Settings',
  邮件设置: 'Mail Settings',
  年度评审启动: 'Start of annual review',
  年度评审设置: 'Annual Review Settings',
  评委名单导入: 'Import of Judges List',
  评委名单评审: 'Judges List Review',
  项目材料上传: 'Project material uploading',
  年度项目总览: 'Annual project overview',
  线上评分: 'Online score',
  年度项目评审: 'Annual Project Review',
  项目意见答疑: 'Project comments and questions',
  整体评审进度: 'Overall review progress',
  个人工作台: 'Personal workbench',
  位置: 'Location',
  我的待办: 'Wait done',
  我的已办: 'Have done',
  立项信息上传: 'Upload project approval information',
  线上材料修改: 'On-line material modification',
  线上材料初审: 'Primary review of online materials',
  申报材料上传: 'Upload of Declaration Materials',
  线上材料终审: 'Final review of on-line materials',
  线上材料终审展示: 'Online material final display',
  公司: 'Company',
  年度: 'Year',
  币种: 'Currency',
  退出: 'LogOut',
  上传文件: 'Template Upload',
  下载: 'Download',
  导出模板: 'Template Download',
  下载模板: 'Template Download',
  保存草稿: 'Save',
  同步校验: 'Sync Verification',
  提交: 'Submit',
  国家: 'Country',
  反垄断财报: 'Fin Report',
  查询: 'Search',
  导出报表: 'Export',
  操作指引: 'Guide',
  展开: 'Expand',
  收起: 'Collapse',
  上一步: 'Back',
  下一步: 'Next',
  数据收集: 'Data Collection',
  反垄断数据收集: 'Data Collection',
  'Revenue Breakdown': 'Revenue Breakdown',
  Revenues: 'Revenues',
  德国: 'Germany',
  加拿大: 'Canada',
  波兰: 'Poland',
  奥地利: 'Austria',
  美国: 'United States',
  韩国: 'South Korea',
  日本: 'Japan',
  收入汇总表: 'Income Summary',
  Subsi报表: 'Subsi Report',
  少数持有人报表: 'Minority Holder Statement',
  客户报表: 'Customer Report',
  供应商报表: 'Supplier Report',
  查询结果: 'Query Result',
  查看明细: 'View Details',
  操作: 'Operate',
  '收入&国别信息-投资子公司': 'Income & Country Information - Investing in Subsidiaries',
  '收入&国别信息-集团本部': 'Income & Country Information - Group Headquarters',
  报表逻辑处理: 'Report Logic Processing',
  反垄断报表逻辑处理: 'Report Logic Processing',
  数据申请: 'Data Request',
  错误信息提示: 'Error Message',
  错误: 'Error',
  警告: 'Warning',
  上传成功: 'Success',
  上传失败: 'Failed',
  数据来源: 'Data Source',
  子集团: 'Sub Group',
  持股比例: 'Shareholding Ratio',
  主营业务: 'Main Business',
  主要实体经营区域: 'Main Business Area',
  注册国家: 'Country of Registration',
  描述: 'Describe',
  层级: 'Hierarchy',
  实收资本: 'Paid-up Capital',
  实收资本的币种: 'Currency of paid-in capital',
  投资主体名称: 'Investment Entity Name',
  出资比例: 'Funded Ratio',
  注册的具体地址: 'Registered Address',
  注册州: 'State of Registration',
  注册城市: 'Registered City',
  注册时间: 'Registration Time',
  上市日期: 'Listing Date',
  来自本地的收入: 'Income from Local',
  全球总收入: 'Total global Revenue',
  净利润: 'Net Profit',
  '加拿大investments<=50%的资产': 'Canadian investments<=50% of assets',
  加拿大PPE的资产: 'Assets of Canadian PPE',
  总资产: 'Total Assets',
  股东权益: 'Shareholders\' Equity',
  填写币种: 'Fill in the Currency',
  主要业务的经营区域: 'Main business Area',
  主要产品: 'Main Products',
  企业地理区域: 'Business Geographic Area',
  收入类别: 'Income Category',
  收入描述: 'Income Description',
  被投资方名字: 'Investee Name',
  客户: 'Client',
  客户所在国家: 'Customer\'s Country',
  总收入: 'Total Revenue',
  电话: 'Phone',
  联系人: 'Contact',
  供应商: 'Supplier',
  供应商所在国家: 'Supplier\'s Country',
  总采购额: 'Total Purchase Amount',
  校验成功: 'Check success',
  提交成功: 'Submit Success',
  查询成功: 'Search Success',
  草稿保存成功: 'Draft Save success',
  导出汇总报表: 'Export Summary Report',
  导出分国家报表: 'Export report by country',
  Entity是必选项: 'Entity Require',
  请选择: 'Please Select',
  查询结果请导出报表查看: 'Please export the report to view the query results!',
  导出记录: 'Export Records',
  导出类型: 'Export Type',
  文件: 'File',
  Entity: 'Entity',
  文件链接: 'File Url',
  反垄断报表导出: 'Antitrust report export',
  请选择需要导出数据的国家: 'Please select the country that needs to export data!',
  请确认已经填写了所有表格的数据: 'Please confirm that all data in the form has been filled！',
  保存中: 'Submiting',
  确定: 'Confirm',
  取消: 'Cancel',
  子公司相关信息: 'Subsidiaries related information',
  'Significant affliate相关信息': 'Significant affliates related information',
  加载中: 'Loading',
  暂无数据: 'Empty Data',
  同步成功: 'Sync Success',
  保存成功: 'Save Success',
  年度不能为空: 'Years is Required',
  导出Excel套表: 'Export Excel',
  请选择国家: 'Please Select Country',
  分国别数据收集: 'Data collection by country',
  音乐相关结算组: 'Music related settlement group',
  音乐相关结算组清单: 'Music related settlement group list',
  微信海外认证费上传: 'WeChat Overseas Certification Fee Upload',
  微信海外认证费: 'WeChat Overseas Certification Fee',
  移动支付账单: 'mobile bill payment',
  海外渠道充值账单: 'Overseas channel recharge bill',
  海外渠道充值账单报表: 'Overseas channel recharge statement',
  'IMS收入-报表渠道剔除清单': 'IMS Revenue - Report channel exclusion list',
  'IMS收入报表-渠道剔除后': 'IMS Revenue Report - Channels excluded',
  '本部收入折算&汇总': 'Income Conversion & Summary of Headquarters',
  '本部收入折算&分部': 'Conversion of head office revenue & Division',
  '本部收入折算&分部汇总报表': 'Division revenue conversion & Division summary report',

  '集团报表折算&汇总': 'Group report conversion & summary',
  '集团报表折算&分部': 'Group statement translation & Division',
  反垄断套表: 'Antitrust Set Form',
  subsi报表: 'subsi report',
  'MAU Breakdown': 'MAU Breakdown',
  期间管理: 'period management',
  填报进度查看和管理: 'Filling progress view and management',

  'EU/EEA': 'EU/EEA',
  Total: 'Total',
  'PC game revenue': 'PC game revenue',
  'Console game revenue': 'Console game revenue',
  'Mobile game revenue': 'Mobile game revenue',
  'Game development services(WFH) revenue': 'Game development services(WFH) revenue',
  'Other game revenue': 'Other game revenue',
  'VAS-others': 'VAS-others',
  'Online Advertising': 'Online Advertising',
  FinTech: 'FinTech',
  'Business Services': 'Business Services',
  Others: 'Others',

  收集阶段: 'Collection stage',
  状态: 'State',
  期间: 'Period',
  添加评论: 'Comment',

  新增年度: 'new year',
  同步数据: 'Synchronous data',
  同步投资门户侧数据: 'Synchronize investment portal data',
  正在同步中: 'In sync',

  期间开启成功: 'Duration open success',
  期间开启失败: 'Duration start failure',
  期间关闭成功: 'Successful shutdown during',
  期间关闭失败: 'Interim shutdown failure',

  全部: 'All',
  公司代码: 'Company code',
  公司名称: 'Company name',
  报告期间: 'Reporting period',
  确认敏感信息: 'Identify sensitive information',
  是否确认敏感信息: 'Whether to confirm sensitive information',
  提示: 'Notice',
  解锁任务: 'Unlock task',
  是否解锁任务: 'Whether to unlock a task',
  是否同步投资门户侧数据: 'Whether to synchronize investment portal data',
  是否同步数据: 'Whether to synchronize data',
  操作成功: 'Successful operation',

  Country: 'Country',
  Description: 'Description',
  'PC game': 'PC game',
  'Console game': 'Console game',
  'Mobile game': 'Mobile game',
  'Other game': 'Other game',
  Segment分部: 'Segment',

  新增行: 'New line',
  结算组代码: 'Clearing group code',
  请填写: 'Please fill in',
  结算组名称: 'Name of clearing unit',
  是否为音乐相关: 'Whether it is musically related',
  是: 'YES',
  否: 'No',
  开始账期: 'Opening period',
  选择日期: 'Select date',
  结束账期: 'Closing period',
  编辑: 'Edit',
  删除: 'Delete',
  请搜索: 'Please Search',

  国别编码: 'Country code',
  '国别(中文)': 'Nationality (Chinese)',
  '认证收入/美元': 'Certified revenue/USD',

  是否删除: 'Delete or not',
  数据: 'Data',

  月份: 'Month',
  OU代码: 'OU code',
  OU名称: 'OU name',
  COA产品代码: 'OU OU name',
  COA产品名称: 'COA product name',
  商业变现形式: 'Form of commercial realization',
  业务类型: 'Type of business',
  平台或产品类型: 'Type of platform or product',
  MDM客户ID: 'MDM customer ID',
  MDM客户名称: 'MDM customer name',
  国别: 'Nationality',
  '结算金额（结算币种）': 'Settlement amount (settlement currency)',
  结算币种: 'Currency of settlement',
  ICP标识: 'ICP label',
  是否为子公司: 'Whether it is a subsidiary',
  '结算金额-不含税': 'Settlement amount - excluding tax',
  '结算金额/0.7': 'Settlement amount/0.7',
  计费金额: 'Charge amount',
  原始计费金额: 'Original charge amount',
  汇率: 'Exchange rate',
  渠道ID: 'Channel ID',
  渠道名称: 'Channel name',
  子渠道ID: 'Subchannel ID',
  子渠道名称: 'Subchannel name',
  原币币种: 'The original currency',
  事业群代码: 'Business group code',
  事业群名称: 'Business group name',
  部门ID: 'Department ID',
  部门名称: 'Department name',
  是否为音乐: 'Whether it is music or not',
  记账币种: 'Currency of account',
  交易币种: 'Currency of transaction',
  账单金额: 'Amount of bill',
  税率: 'Tax rate',
  劳务费率: 'Service rate',

  国家英文名称: 'English name of country',
  国家中文名称: 'Chinese name of country',
  子集团实体编码: 'Subgroup entity code',
  子集团实体名称: 'Subgroup entity name',
  'Total revenue': 'Total revenue',
  国家代码: 'Country code',
  子集团实体描述: 'Subgroup entity description',
  OU数据类别: 'OU data type',
  是否注册在本地: 'Whether to register locally',
  '注册国家, 使用COA国别区域段': 'Country of registration',
  '注册州(美国专用)': 'State of Registration (USA only)',
  '注册城市(美国专用)': 'Registered City (USA only)',
  '加拿大investments<50%的资产': 'Canadian investments<50% of assets',
  加拿大ppe的资产: 'The assets of the Canadian ppe',
  OU类型: 'OU type',
  主要产品for加拿大: 'The main products are for Canada',
  企业地理区域for加拿大: 'Corporate geographic area for Canada',
  收入类别for美国: 'Income category for the United States',
  全球总收入币种: 'Total global revenue in currency',
  是否原始币种: 'Whether it is the original currency',
  '收入折算&汇总': 'Revenue translation & summary',

  剔除类型: 'Culling type',
  剔除原因: 'Eliminate the cause',
  最后更新人: 'Last update',
  最后更新时间: 'Last update time',
  国内: 'Domestic',
  国外: 'Abroad',
  是否国内: 'Domestic or not',

  账期: 'Account period',
  来源: 'Source',
  业务口径: 'Business caliber',
  渠道类型: 'Channel type',
  MDM地区: 'MDM region',
  MDM国籍: 'MDM nationality',
  'MDM国籍（中文）': 'MDM Nationality (Chinese)',
  '业务国别-CODE': 'Business country -CODE',
  '业务国别-中文': 'Country of operation - Chinese',
  '国别-经确认（COA CODE）': 'Country - Confirmed (COA CODE)',
  '国别-经确认（中文）': 'Country - Confirmed (Chinese)',
  业务客户ID: 'Business customer ID',
  业务客户名称: 'Business customer name',
  '账务最终收入Y1（CNY币种）': 'Final accounting income Y1 (CNY currency)',
  '账务最终收入-税后Y（CNY币种）': 'Final accounting revenue - Y (CNY currency) after tax',
  税前收入原币: 'Pre-tax income in original currency',
  税后收入原币: 'After tax income in original currency',
  批号: 'Batch number',
  订单号: 'Order number',
  合同号: 'Contract number',
  开始: 'Start',
  结束: 'End',


  '计费金额（结算币种）': 'Billing amount (currency of settlement)',
  '计费金额（原始币种）': 'Amount charged (in original currency)',
  '结算金额（人民币）': 'Settlement amount (RMB)',
  '结算金额（原始币种）': 'Settlement amount (original currency)',
  '计费金额（人民币）': 'Billing amount (RMB)',
  商户类型: 'Merchant type',

  返回: 'Back',

  正在进行中: 'Loading',

  错误所在sheet: 'Error sheet',
  行: 'Line',
  错误信息: 'Error message',
  错误级别: 'Error level',

  导出: 'Export',
  是否修改国别: 'Whether to modify the country',
  海外: 'Overseas',
  更新: 'Update',
  评论: 'Comment',
  是否有评论: 'isComment',
  年份: 'Year',
  '音乐相关结算组-国内': 'Music related Clearing Group - Domestic',
  '音乐相关结算组-海外': 'Music Related Clearing Section - Overseas',
  'IMS收入-报表渠道剔除清单-国内': 'IMS Revenue - Report channel exclusion List - Domestic',
  'IMS收入-报表渠道剔除清单-海外': 'IMS Revenue - Report channel exclusion List - Overseas',
  'IMS收入报表-渠道剔除后-国内': 'IMS Revenue Report - Channels excluded - Domestic',
  'IMS收入报表-渠道剔除后-海外': 'IMS Revenue Report - Channels excluded - overseas',
  'Revenue Breakdown比对': 'Comparison of Revenue Breakdown',
  'Revenue breakdown比对': 'Comparison of Revenue Breakdown',
  是否需要填报: 'Whether to fill in',

  '操作成功，同步结果将邮件通知，请注意查收！': 'The operation is successful, the synchronization result will be notified by email, please pay attention to check!',

  填报公司: 'Company',
  '公司代码（ICP）': 'Company code (ICP)',
  填报年度: 'Year',
  查看: 'View',
  填报历史记录查询: 'Historical data',
  填报历史查询: 'Historical data',
  'from 币种': 'From Currency',
  'to 币种': 'To Currency',
  期间范围: 'Period',
};
