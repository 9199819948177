import { Noop } from '@/utils';
import { RouteConfig } from 'vue-router';

// export default {
//   path: '/home',
//   name: '主页',
//   component: home,
// };

const childRouter: RouteConfig = {
  path: 'sysConfig',
  name: '系统设置',
  redirect: '/sysConfig/periodManagement',
  component: Noop,
  meta: {
    show: true,
  },
  children: [
    {
      path: 'periodManagement',
      name: '期间管理',
      component: () => import('@/views/periodManagement'),
    },
    {
      path: 'taskManagement',
      name: '填报进度查看和管理',
      component: () => import('@/views/taskManagement'),
    },
    {
      path: 'progressCheck',
      name: 'Revenue Breakdown比对',
      component: () => import('@/views/progressCheck'),
    },

  ],
};

export default childRouter;
