import axiosInstantiation from './axios';
import moment from 'moment';
import { MIME, RepType, CodedType, ILooseObject } from './commonType';

export * from './commonType';

/**
 * get 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const get = async (
  url: any,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any>  => {
  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': `${MIME.json};${CodedType.utf8}`,
    },
    responseType: RepType.json,
  };

  return axiosInstantiation(params, reqConf, fn).get(url, { params });
};

/**
 * post 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const post = async (
  url: string,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any> => {
  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': `${MIME.json};${CodedType.utf8}`,
    },
    responseType: RepType.json,
  };

  return axiosInstantiation(params, reqConf, fn).post(url);
};

/**
 * 上传 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const upload = async (
  url: string,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any> => {
  // 将参数转换为 fromdata 类型
  const formData: FormData = new FormData();

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < params.length; i++) {
    formData.append('file', params[i]);
  }

  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': MIME.mform,
    },
    responseType: RepType.json,
  };

  return axiosInstantiation(formData, reqConf, fn).post(url);
};

/**
 *  form 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const form = async (
  url: string,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any> => {
  // 将参数转换为 fromdata 类型
  const formData: FormData = new FormData();

  Object.keys(params).forEach((key: any) => {
    formData.append(key, params[key]);
  });

  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': MIME.form,
    },
    responseType: RepType.json,
  };

  return axiosInstantiation(formData, reqConf, fn).post(url);
};

/**
 * 下载 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const download = async   (
  url: any,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any> => {
  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    responseType: RepType.blob,
  };

  return axiosInstantiation(params, reqConf, (config: any) => {
    // 返回流类型
    // const contentType: string = config.headers['content-type'];
    // 返回文件名
    let contentDisposition: string = config.headers['content-disposition'];

    console.log(' axiosInstantiation >>>>>>>>> config :', config);
    console.log(' axiosInstantiation >>>>>>>>> contentDisposition :', contentDisposition);

    if (!contentDisposition) {
      // 如果文件未命名，设置默认命名“下载文件_当前时间戳”
      const time: Date = new Date();

      contentDisposition = `;filename=下载文件_${moment(time).valueOf()}.xlsx`;
    }
    const fileName: string = window.decodeURI(contentDisposition.split('filename=')[1]);
    // 文件类型
    const [, suffix] = fileName.split('.');
    let type: string = MIME[suffix];

    if (!type) {
      type = MIME.zip;
    }
    const link: any = document.createElement('a');

    const blob: any = new Blob([config.data], {
      type: MIME[suffix],
    });

    link.style.display = 'none';
    link.href = URL.createObjectURL(blob); // 创建url对象
    link.download = fileName; // 下载后文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href); // 销毁url对象
    fn();
  }).post(url);
};

/**
 * 获取流 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const stream  = (
  url: any,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any> => {
  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': `${MIME.json};${CodedType.utf8}`,
    },
    responseType: RepType.blob,
  };

  return axiosInstantiation(params, reqConf, fn).post(url);
};


/**
 * 获取流 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const stream_get  = (
  url: any,
  params: any,
  fn: any = (_e: any) => {
    //
    console.log(_e);
  }
): Promise<any> => {
  const reqConf: ILooseObject = {
    headers: {
      // Accept: MIME.json,
      // 'Content-Type': `${MIME.json};${CodedType.utf8}`,
    },
    responseType: RepType.blob,
  };

  return axiosInstantiation(params, reqConf, (config: any) => {
    let contentDisposition: string = config.headers['content-disposition'];
    if (!contentDisposition) {
      // 如果文件未命名，设置默认命名“下载文件_当前时间戳”
      const time: Date = new Date();
      contentDisposition = `;filename=*=utf-8''下载文件_${moment(time).valueOf()}.xlsx`;
    }
    console.log(contentDisposition.split('filename*=utf-8\'\''));
    //
    const fileName: string = window.decodeURI(contentDisposition.split('filename*=utf-8\'\'')[1]);
    // 文件类型
    const [, suffix] = fileName.split('.');
    let type: string = MIME[suffix];

    if (!type) {
      type = MIME.zip;
    }

    const link: any = document.createElement('a');

    const blob: any = new Blob([config.data], {
      type: MIME[suffix],
    });

    link.style.display = 'none';
    link.href = URL.createObjectURL(blob); // 创建url对象
    link.download = fileName; // 下载后文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href); // 销毁url对象
  }).get(url, { params });
};

/**
 * delete 请求
 * @param url 请求连接
 * @param params 参数
 * @returns 返回值
 */
export const axios_delete = async (
  url: any,
  params: any,
  fn: any = (_e: any) => {
    //
  }
): Promise<any>  => {
  const reqConf: ILooseObject = {
    headers: {
      Accept: MIME.json,
      'Content-Type': `${MIME.json};${CodedType.utf8}`,
    },
    responseType: RepType.json,
  };

  return axiosInstantiation(params, reqConf, fn).delete(url, { params });
};
