/**
 * 修改表格头部样式 (弃用 建议使用global.css中的headerCell样式类)
 * @params
 * @return
 */
export const getRowClass = ({ rowIndex }: any) => {
  if (rowIndex === 0) {
    return 'background: rgba(238,241,246,1);font-size:14px;font-weight:600;color:#0D4B9A';
  }
  return '';
};
  /**
   * 格式化时间
   * @params 2022-04-22T07:02:36.000+0000
   * @return YYYY-MM-DD HH:mm
   */
export const getRuleTime = (date: any) => {
  if (date) {
    const dt = new Date(date);
    const y = dt.getFullYear();
    const m = (`${dt.getMonth() + 1}`).padStart(2, '0');
    const d = (`${dt.getDate()}`).padStart(2, '0');
    const hh = (`${dt.getHours()}`).padStart(2, '0');
    const mm = (`${dt.getMinutes()}`).padStart(2, '0');
    // const ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}`;
  }
};
  /**
   * 防抖函数
   * @params 2022-04-22T07:02:36.000+0000
   * @return YYYY-MM-DD HH:mm
   */

export const debounce = (func: any, timeout: number) => {
  let timer: any = null;
  return function () {
    if (timer) {
      // 如果在timeout秒调用 清空定时器
      clearTimeout(timer);
    }
    // 每次调用都指定timeout后再执行
    timer = setTimeout(() => {
      timer = null;
      func();
    }, timeout);
  };
};
  /**
    * 数值类型格式化 （千分位+保留两位小数）
    */
export const dataFormatter = (value: any) => {
  if (typeof value === 'number') {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  if (!isNaN(Number(value))) {
    return Number(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  return value;
};

export interface LocationSearch {
  appId?: string;
  tenantId?: string;
  forceversion?: 'v1' | 'v2' | string;
}

// 将url中的查询字段，格式化成对象
export const parseSearch = (search: string = location.search, omitKeys: string[] = []): LocationSearch => {
  const qs = new URLSearchParams(search);
  const result  = {} as Record<string, any>;
  for (const [key, val] of qs) {
    if (omitKeys.length && omitKeys.includes(key)) continue;
    result[key] = val;
  }
  return result;
};

// 拼接为queryString
export const joinParams = (params: Record<string, any>, withQuestion = true) => (withQuestion ? '?' : '')
    + Object.keys(params)
      .filter(key => !!key)
      .map(key => `${key}=${encodeURIComponent(decodeURIComponent(params[key]))}`)
      .join('&');


// 返回排除某些查询参数的url地址
export const urlOmitParams = (...keys: string[]) => {
  const { href, protocol, hostname, pathname, search } = location;
  if (!search) return href;
  const params = parseSearch(search, keys);
  return `${protocol}//${hostname}${pathname}${joinParams(params)}`;
};


const formatfieldType = (keyItemObj, item, ele) => {
  // console.log(keyItemObj, item, ele);
  if (keyItemObj[ele].fieldType === 'BigDecimal') {
    return item[ele].replace(/\$\s?|(,*)/g, '');
  }
  return item[ele];
};

// 处理待提交数据 根据leafColumnKeys  过滤 data 返回过滤后的数据
export const  untilSubmitData = (data: Array<any>, leafColumnKeys: Array<any>, keyItemObj?: any): Array<any> =>  {
  // console.log(data,leafColumnKeys);
  const at: Array<any> = [];
  data.forEach((item) => {
    let st = {};
    //  根据 leafColumnKeys  过滤 sourceTable,因为后端会有多余的数据
    leafColumnKeys.forEach((itele) => {
      st = {
        ...st,
        // [itele]: item[itele] ? item[itele].replace(/\$\s?|(,*)/g, '') : item[itele],
        [itele]: formatfieldType(keyItemObj, item, itele),
      };
    });
    // 当一行数据有一个数据则提交该行
    const tol = Object.values(st).filter(item => item !== '' && item !== null && item !== undefined);
    if (tol.length >= 1) {
      at.push(st);
    }
  });
  return at;
};
