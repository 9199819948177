import { Noop } from '@/utils';
import { RouteConfig } from 'vue-router';

// export default {
//   path: '/home',
//   name: '主页',
//   component: home,
// };

const childRouter: RouteConfig = {
  path: '/home',
  name: '首页',
  redirect: '/home/index',
  component: Noop,
  meta: {
    show: false,
  },
  children: [
    {
      path: 'business',
      name: '业务页面',
      component: () => import('@/views/business'),
    },
    {
      path: 'blank',
      name: '企业票池',
      component: () => import('@/views/blank'),
    },
    {
      path: 'index',
      name: '首页',
      component: () => import('@/views/home'),
    },
  ],
};

export default childRouter;
