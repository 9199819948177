import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import filter from './filter';

/** 布局 */
// const Layout = (): any => import('@/layout');
// const errorPage = () => import('@/views/errorPage');

// ********* 页面 *********
import ModulesHome from './modules/home';
// import ModulesIncome from './modules/income';
// import ModulesInvoicePond from './modules/InvoicePond';
// import ModulesIssueInvoice from './modules/IssueInvoice';
// import ModulesSetting from './modules/setting';
import ModuleDataStore from './modules/dataStore';
import ModuleTableLogic from './modules/tableLogic';
import ModuleFinReport from './modules/finReport';
import ModuleDataApply from './modules/dataApply';
import ModuleSysConfig from './modules/sysConfig';

import NProgress from 'nprogress'; // 引入nprogress插件

// 全局配置
const publicPath = '/tfdp-antitrust';
// 是否启用路由过滤
const FLAG_ROUTE_FILTER = false;

Vue.use(Router);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '反垄断系统',
    redirect: '/store',
    component: () => import('@/components/layout'),
    children: [
      ModuleDataStore,
      ModuleTableLogic,
      ModuleFinReport,
      ModuleDataApply,
      ModuleSysConfig,
      ModulesHome, // 首页
      // ModulesIncome, // 进项管理
      // ModulesInvoicePond, // 企业票池
      // ModulesIssueInvoice, // 开票管理
      // ModulesSetting, // 系统设置

    ],
  },
  {
    path: '/noPermission',
    name: '无权限',
    component: () => import('@/views/common'),
  },
  {
    path: '/error',
    name: '错误页',
    component: () => import('@/views/common'),
  },
  {
    path: '*',
    name: '无效路由',
    redirect: '/error',
  },
];

const router: any = new Router({
  mode: 'history', // 分为 hash（地址栏#号） 和 history（ 利用了 HTML5 History Interface 中新增的 pushState() 和 replaceState() 方法，此方式需要浏览器的支持，而且需要后端配合,才能达到预期效果）
  base: publicPath, // 应用的基路径。例如，如果整个单页应用服务在 /app/ 下，然后 base 就应该设为 "/app/"。
  routes,
});

router.beforeEach(async (to: any, from: any, next: any) => {
  // window.document.title = `${to.name}`;
  NProgress.start(); // 设置加载进度条(开始..)

  // 如果服务器菜单此处要做权限过滤
  if (FLAG_ROUTE_FILTER) {
    await filter(to, next);
  } else {
    next();
  }
  // await isNeedLogin(to, from, next); // 登录过滤
});

router.afterEach(() => {
  NProgress.done(); // 设置加载进度条(结束..)
});

export default router;
