import { Noop } from '@/utils';
import { RouteConfig } from 'vue-router';

// export default {
//   path: '/home',
//   name: '主页',
//   component: home,
// };

const childRouter: RouteConfig = {
  path: '/store',
  name: '数据收集',
  redirect: '/store/child',
  component: Noop,
  meta: {
    show: true,
  },
  children: [
    {
      path: 'child',
      name: '收入&国别信息-投资子公司',
      component: () => import('@/views/dataStore'),
    },
    {
      path: 'group',
      name: '收入&国别信息-集团本部',
      component: () => import('@/views/about'),
    },
    // {
    //   path: 'musicSettlementList',
    //   name: '音乐相关结算组清单',
    //   component: () => import('@/views/musicSettlementList'),
    //   children: [
    //     {
    //       path: '/store/musicSettlementList/:path',
    //       name: '音乐相关结算组清单',
    //       component: () => import('@/views/channelList'),
    //     },
    //   ],
    // },
    {
      path: 'musicSettlementList/:path',
      name: '音乐相关结算组清单',
      component: () => import('@/views/musicSettlementList'),
    },
    {
      path: 'wechartOverseas',
      name: '微信海外认证费',
      component: () => import('@/views/wechartOverseas'),
    },
    {
      path: 'mobilePaymentBill',
      name: '移动支付账单',
      component: () => import('@/views/mobilePaymentBill'),
    },
    {
      path: 'overseasRechargeBill',
      name: '海外渠道充值账单',
      component: () => import('@/views/overseasRechargeBill'),
    },
    {
      path: 'channelList/:path',
      name: 'IMS收入-报表渠道剔除清单',
      component: () => import('@/views/channelList'),
      // children: [
      //   {
      //     path: '/store/channelList/:path',
      //     name: 'IMS收入-报表渠道剔除清单',
      //     component: () => import('@/views/channelList'),
      //   },
      // ],
    },
    // {
    //   path: 'imsRevenue',
    //   name: 'IMS收入-全流程剔除后',
    //   component: () => import('@/views/imsRevenue'),
    //   children: [
    //     {
    //       path: '/store/imsRevenue/:path',
    //       name: 'IMS收入-全流程剔除后',
    //       component: () => import('@/views/imsRevenue'),
    //     },
    //   ],
    // },
    {
      path: 'imsRevenue/:path',
      name: 'IMS收入-全流程剔除后',
      component: () => import('@/views/imsRevenue'),
      // children: [
      //   {
      //     path: '/store/imsRevenue/:path',
      //     name: 'IMS收入-全流程剔除后',
      //     component: () => import('@/views/imsRevenue'),
      //   },
      // ],
    },
    {
      path: 'historyQuery',
      name: '	填报历史查询',
      component: () => import('@/views/historyQuery'),
    },
  ],
};

export default childRouter;
