import { setLS, getLS, previewLog } from '@/utils';
import { getCurrentUserRoles, getCurrentUserMenu } from '@/service/user';

/** 访问权限过滤 */
const RouteFilter = async (to: any, next: any) => {
  // 忽略的路由
  const ignoreRoutes: string[] = ['/noPermission', '/error'];

  let ignore = false;

  ignoreRoutes.forEach((item: any) => {
    if (item === to.path) {
      ignore = true;
      return;
    }
  });

  if (ignore) {
    next();
    return;
  }

  // ********* 人员权限处理 *********


  // if (!permissions) {
  //   const res: any = await getUserInfo({});// 获取用户信息
  //   store.commit('setUserInfo', res.data);
  // }
  // // ********* 菜单权限 *********

  const userInfo: any = getLS('user');
  const { permissions, menus }: any = userInfo;
  const [one] = menus;
  const flatmenusUrl: any = ['/'];
  menus.forEach((item: any) => {
    flatmenusUrl.push(item.url);
    if (item.children) {
      item.children.forEach((sitem) => {
        flatmenusUrl.push(sitem.url);
        if (sitem.children) {
          sitem.children.forEach((scitem) => {
            flatmenusUrl.push(scitem.url);
          });
        }
      });
    }
  });
  console.log(flatmenusUrl, permissions);
  console.log(to.path, '----->', flatmenusUrl.includes(to.path));
  if (permissions) {
    // const paths: Array<string> = permissions.map((item: any) => `/${item.code.replace(/_/g, "/")}`);
    // if (paths.includes(to.path)) {
    if (flatmenusUrl.includes(to.path)) {
      next(); // 正常访问
    } else {
      next('/noPermission'); // 跳转无权限页面
    }
  } else {
    next('/');
  }

  // if (!userInfo) {
  //   // 用户为空时请求数据
  //   const resUsr: any = await getCurrentUserRoles();

  //   userInfo = resUsr;
  //   setLS('t_c_p_r_user', userInfo);
  // }

  // 记录访问数据
  previewLog(to.path);

  // // ********* 菜单权限 *********
  // // if (userInfo.roleType === 'NoPermission') {
  // if (userInfo.roleType.length === 0) {
  //   next('/noPermission'); // 跳转无权限页面
  // } else {
  //   let userMenus: any = await getLS('user');

  //   // if (!userMenus) {
  //   //   // 用户为空时请求数据
  //   //   const resMenus: any = await getCurrentUserMenu();

  //   //   // console.log('RouteFilter >>>>>>>>> resMenus : ', resMenus);

  //   //   userMenus = resMenus;
  //   //   await setLS('t_c_p_r_menus', userMenus);
  //   // }

  //   // console.log('RouteFilter >>>>>>>>> userMenus : ', userMenus);

  //   next(); // 正常访问

  //   // let canView: boolean = false;

  //   // userMenus.map((item: any) => {
  //   // 	if (item.url === to.path) {
  //   // 		canView = true;
  //   // 		return;
  //   // 	}

  //   // 	if (item.children.length > 0) {
  //   // 		item.children.map((ele: any) => {
  //   // 			if (ele.url === to.path) {
  //   // 				canView = true;
  //   // 				return;
  //   // 			}
  //   // 		});
  //   // 	}
  //   // });

  //   // if (canView) {
  //   // 	next(); // 正常访问
  //   // } else {
  //   // 	next('/noPermission'); // 跳转无权限页面
  //   // }
  // }
};

export default RouteFilter;
