export const zh: any = {
  noop: 'noop', // 过滤无需命名路由
  '腾讯公司 S2-财经线/财经IT管理部': '腾讯公司 S2-财经线/财经IT管理部',
  日期: '日期', // 'Date',
  姓名: '姓名', // 'Name',
  地址: '地址', // 'Address',
  首页: '首页', // 'Home',
  系统设置: '系统设置', // 'System Settings',
  反垄断系统设置: '反垄断系统设置', // 'System Settings',
  邮件设置: '邮件设置', // 'Mail Settings',
  年度评审启动: '年度评审启动', // 'Start of annual review',
  年度评审设置: '年度评审设置', // 'Annual Review Settings',
  评委名单导入: '评委名单导入', // 'Import of Judges List',
  评委名单评审: '评委名单评审', // 'Judges List Review',
  项目材料上传: '项目材料上传', // 'Project material uploading',
  年度项目总览: '年度项目总览', // 'Annual project overview',
  线上评分: '线上评分', // 'Online score',
  年度项目评审: '年度项目评审', // 'Annual Project Review',
  项目意见答疑: '项目意见答疑', // 'Project comments and questions',
  整体评审进度: '整体评审进度', // 'Overall review progress',
  个人工作台: '个人工作台', // 'Personal workbench',
  位置: '位置', // 'Location',
  我的待办: '我的待办', // 'Wait done',
  我的已办: '我的已办', // 'Have done',
  立项信息上传: '立项信息上传', // 'Upload project approval information',
  线上材料修改: '线上材料修改', // 'On-line material modification',
  线上材料初审: '线上材料初审', // 'Primary review of online materials',
  申报材料上传: '申报材料上传', // 'Upload of Declaration Materials',
  线上材料终审: '线上材料终审', // 'Final review of on-line materials',
  线上材料终审展示: '线上材料终审展示', // 'Online material final display',
  公司: '公司',
  年度: '年度',
  币种: '币种',
  退出: '退出',
  上传文件: '上传文件',
  下载: '下载',
  导出模板: '导出模板',
  下载模板: '下载模板',
  保存草稿: '保存草稿',
  同步校验: '同步校验',
  提交: '提交',
  国家: '国家',
  反垄断财报: '反垄断财报',
  查询: '查询',
  导出报表: '导出报表',
  操作指引: '操作指引',
  展开: '展开',
  收起: '收起',
  上一步: '上一步',
  下一步: '下一步',
  数据收集: '数据收集',
  反垄断数据收集: '反垄断数据收集',
  'Revenue Breakdown': 'Revenue Breakdown',
  Revenues: 'Revenues',
  德国: '德国',
  加拿大: '加拿大',
  波兰: '波兰',
  奥地利: '奥地利',
  美国: '美国',
  韩国: '韩国',
  日本: '日本',
  收入汇总表: '收入汇总表',
  Subsi报表: 'Subsi报表',
  少数持有人报表: '少数持有人报表',
  客户报表: '客户报表',
  供应商报表: '供应商报表',
  查询结果: '查询结果',
  查看明细: '查看明细',
  操作: '操作',
  '收入&国别信息-投资子公司': '收入&国别信息-投资子公司',
  '收入&国别信息-集团本部': '收入&国别信息-集团本部',
  报表逻辑处理: '报表逻辑处理',
  反垄断报表逻辑处理: '反垄断报表逻辑处理',
  数据申请: '数据申请',
  错误信息提示: '错误信息提示',
  错误: '错误',
  警告: '警告',
  上传成功: '上传成功',
  上传失败: '上传失败',
  数据来源: '数据来源',
  子集团: '子集团',
  持股比例: '持股比例',
  主营业务: '主营业务',
  主要实体经营区域: '主要实体经营区域',
  注册国家: '注册国家',
  描述: '描述',
  层级: '层级',
  实收资本: '实收资本',
  实收资本的币种: '实收资本的币种',
  投资主体名称: '投资主体名称',
  出资比例: '出资比例',
  注册的具体地址: '注册的具体地址',
  注册州: '注册州',
  注册城市: '注册城市',
  注册时间: '注册时间',
  上市日期: '上市日期',
  来自本地的收入: '来自本地的收入',
  全球总收入: '全球总收入',
  净利润: '净利润',
  '加拿大investments<=50%的资产': '加拿大investments<=50%的资产',
  加拿大PPE的资产: '加拿大PPE的资产',
  总资产: '总资产',
  股东权益: '股东权益',
  填写币种: '填写币种',
  主要业务的经营区域: '主要业务的经营区域',
  主要产品: '主要产品',
  企业地理区域: '企业地理区域',
  收入类别: '收入类别',
  收入描述: '收入描述',
  被投资方名字: '被投资方名字',
  客户: '客户',
  客户所在国家: '客户所在国家',
  总收入: '总收入',
  电话: '电话',
  联系人: '联系人',
  供应商: '供应商',
  供应商所在国家: '供应商所在国家',
  总采购额: '总采购额',
  校验成功: '校验成功',
  提交成功: '提交成功',
  查询成功: '查询成功',
  草稿保存成功: '草稿保存成功',
  导出汇总报表: '导出汇总报表',
  导出分国家报表: '导出分国家报表',
  Entity是必选项: 'Entity是必选项',
  请选择: '请选择',
  查询结果请导出报表查看: '查询结果请导出报表查看！',
  导出记录: '导出记录',
  导出类型: '导出类型',
  文件: '文件',
  Entity: 'Entity',
  文件链接: '文件链接',
  反垄断报表导出: '反垄断报表导出',
  请选择需要导出数据的国家: '请选择需要导出数据的国家！',
  请确认已经填写了所有表格的数据: '请确认已经填写了所有表格的数据！',
  保存中: '保存中',
  确定: '确定',
  取消: '取消',
  子公司相关信息: '子公司相关信息',
  'Significant affliate相关信息': 'Significant affliate相关信息',
  加载中: '加载中',
  暂无数据: '暂无数据',
  同步成功: '同步成功',
  保存成功: '保存成功',
  年度不能为空: '年度不能为空',
  导出Excel套表: '导出Excel套表',
  请选择国家: '请选择国家',
  分国别数据收集: '分国别数据收集',
  音乐相关结算组: '音乐相关结算组',
  音乐相关结算组清单: '音乐相关结算组清单',
  微信海外认证费上传: '微信海外认证费上传',
  微信海外认证费: '微信海外认证费',
  移动支付账单: '移动支付账单',
  海外渠道充值账单: '海外渠道充值账单',
  海外渠道充值账单报表: '海外渠道充值账单报表',
  'IMS收入-报表渠道剔除清单': 'IMS收入-报表渠道剔除清单',
  'IMS收入报表-渠道剔除后': 'IMS收入报表-渠道剔除后',
  '本部收入折算&汇总': '本部收入折算&汇总',
  '本部收入折算&分部': '本部收入折算&分部',
  '本部收入折算&分部汇总报表': '本部收入折算&分部汇总报表',
  '集团报表折算&汇总': '集团报表折算&汇总',
  '集团报表折算&分部': '集团报表折算&分部',
  反垄断套表: '反垄断套表',
  subsi报表: 'subsi报表',
  'MAU Breakdown': 'MAU Breakdown',
  期间管理: '期间管理',
  填报进度查看和管理: '填报进度查看和管理',

  'EU/EEA': 'EU/EEA',
  Total: 'Total',
  'PC game revenue': 'PC game revenue',
  'Console game revenue': 'Console game revenue',
  'Mobile game revenue': 'Mobile game revenue',
  'Game development services(WFH) revenue': 'Game development services(WFH) revenue',
  'Other game revenue': 'Other game revenue',
  'VAS-others': 'VAS-others',
  'Online Advertising': 'Online Advertising',
  FinTech: 'FinTech',
  'Business Services': 'Business Services',
  Others: 'Others',

  收集阶段: '收集阶段',
  状态: '状态',
  期间: '期间',
  添加评论: '添加评论',

  新增年度: '新增年度',
  同步数据: '同步数据',
  同步投资门户侧数据: '同步投资门户侧数据',
  正在同步中: '正在同步中',

  期间开启成功: '期间开启成功',
  期间开启失败: '期间开启失败',
  期间关闭成功: '期间关闭成功',
  期间关闭失败: '期间关闭失败',

  全部: '全部',
  公司代码: '公司代码',
  公司名称: '公司名称',
  报告期间: '报告期间',
  确认敏感信息: '确认敏感信息',
  是否确认敏感信息: '是否确认敏感信息',
  提示: '提示',
  解锁任务: '解锁任务',
  是否解锁任务: '是否解锁任务',
  是否同步投资门户侧数据: '是否同步投资门户侧数据',
  是否同步数据: '是否同步数据',
  操作成功: '操作成功',

  Country: '国家英文名称',
  Description: '国家中文名称',
  'PC game': 'PC游戏MAU',
  'Console game': '页游MAU',
  'Mobile game': '手游MAU',
  'Other game': '其他游戏MAU',
  Segment分部: 'Segment分部',


  新增行: '新增行',
  结算组代码: '结算组代码',
  请填写: '请填写',
  结算组名称: '结算组名称',
  是否为音乐相关: '是否为音乐相关',
  是: '是',
  否: '否',
  开始账期: '开始账期',
  选择日期: '选择日期',
  结束账期: '结束账期',
  编辑: '编辑',
  删除: '删除',
  请搜索: '请搜索',

  国别编码: '国别编码',
  '国别(中文)': '国别(中文)',
  '认证收入/美元': '认证收入/美元',

  是否删除: '是否删除',
  数据: '数据',

  月份: '月份',
  OU代码: 'OU代码',
  OU名称: 'OU名称',
  COA产品代码: 'COA产品代码',
  COA产品名称: 'COA产品名称',
  商业变现形式: '商业变现形式',
  业务类型: '业务类型',
  平台或产品类型: '平台或产品类型',
  MDM客户ID: 'MDM客户ID',
  MDM客户名称: 'MDM客户名称',
  国别: '国别',
  '结算金额（结算币种）': '结算金额（结算币种）',
  结算币种: '结算币种',
  ICP标识: 'ICP标识',
  是否为子公司: '是否为子公司',
  '结算金额-不含税': '结算金额-不含税',
  '结算金额/0.7': '结算金额/0.7',
  计费金额: '计费金额',
  原始计费金额: '原始计费金额',
  汇率: '汇率',
  渠道ID: '渠道ID',
  渠道名称: '渠道名称',
  子渠道ID: '子渠道ID',
  子渠道名称: '子渠道名称',
  原币币种: '原币币种',
  事业群代码: '事业群代码',
  事业群名称: '事业群名称',
  部门ID: '部门ID',
  部门名称: '部门名称',
  是否为音乐: '是否为音乐',
  记账币种: '记账币种',
  交易币种: '交易币种',
  账单金额: '账单金额',
  税率: '税率',
  劳务费率: '劳务费率',

  国家英文名称: '国家英文名称',
  国家中文名称: '国家中文名称',
  子集团实体编码: '子集团实体编码',
  子集团实体名称: '子集团实体名称',
  'Total revenue': 'Total revenue',
  国家代码: '国家代码',
  子集团实体描述: '子集团实体描述',
  OU数据类别: 'OU数据类别',
  是否注册在本地: '是否注册在本地',
  '注册国家, 使用COA国别区域段': '注册国家, 使用COA国别区域段',
  '注册州(美国专用)': '注册州(美国专用)',
  '注册城市(美国专用)': '注册城市(美国专用)',
  '加拿大investments<50%的资产': '加拿大investments<50%的资产',
  加拿大ppe的资产: '加拿大ppe的资产',
  OU类型: 'OU类型',
  主要产品for加拿大: '主要产品for加拿大',
  企业地理区域for加拿大: '企业地理区域for加拿大',
  收入类别for美国: '收入类别for美国',
  全球总收入币种: '全球总收入币种',
  是否原始币种: '是否原始币种',
  '收入折算&汇总': '收入折算&汇总',

  剔除类型: '剔除类型',
  剔除原因: '剔除原因',
  最后更新人: '最后更新人',
  最后更新时间: '最后更新时间',
  国内: '国内',
  国外: '国外',
  是否国内: '是否国内',

  账期: '账期',
  来源: '来源',
  业务口径: '业务口径',
  渠道类型: '渠道类型',
  MDM地区: 'MDM地区',
  MDM国籍: 'MDM国籍',
  'MDM国籍（中文）': 'MDM国籍（中文）',
  '业务国别-CODE': '业务国别-CODE',
  '业务国别-中文': '业务国别-中文',
  '国别-经确认（COA CODE）': '国别-经确认（COA CODE）',
  '国别-经确认（中文）': '国别-经确认（中文）',
  业务客户ID: '业务客户ID',
  业务客户名称: '业务客户名称',
  '账务最终收入Y1（CNY币种）': '账务最终收入Y1（CNY币种）',
  '账务最终收入-税后Y（CNY币种）': '账务最终收入-税后Y（CNY币种）',
  税前收入原币: '税前收入原币',
  税后收入原币: '税后收入原币',
  批号: '批号',
  订单号: '订单号',
  合同号: '合同号',
  开始: '开始',
  结束: '结束',

  '计费金额（结算币种）': '计费金额（结算币种）',
  '计费金额（原始币种）': '计费金额（原始币种）',
  '结算金额（人民币）': '结算金额（人民币）',
  '结算金额（原始币种）': '结算金额（原始币种）',
  '计费金额（人民币）': '计费金额（人民币）',
  商户类型: '商户类型',

  返回: '返回',
  正在进行中: '正在进行中',

  错误所在sheet: '错误所在sheet',
  行: '行',
  错误信息: '错误信息',
  错误级别: '错误级别',

  导出: '导出',
  是否修改国别: '是否修改国别',
  海外: '海外',
  更新: '更新',
  评论: '评论',
  是否有评论: '是否有评论',
  年份: '年份',
  '音乐相关结算组-国内': '音乐相关结算组-国内',
  '音乐相关结算组-海外': '音乐相关结算组-海外',
  'IMS收入-报表渠道剔除清单-国内': 'IMS收入-报表渠道剔除清单-国内',
  'IMS收入-报表渠道剔除清单-海外': 'IMS收入-报表渠道剔除清单-海外',
  'IMS收入报表-渠道剔除后-国内': 'IMS收入报表-渠道剔除后-国内',
  'IMS收入报表-渠道剔除后-海外': 'IMS收入报表-渠道剔除后-海外',
  'Revenue Breakdown比对': 'Revenue Breakdown比对',
  是否需要填报: '是否需要填报',
  'Revenue breakdown比对': 'Revenue breakdown比对',
  '操作成功，同步结果将邮件通知，请注意查收！': '操作成功，同步结果将邮件通知，请注意查收！',

  填报公司: '填报公司',
  '公司代码（ICP）': '公司代码（ICP）',
  填报年度: '填报年度',
  查看: '查看',
  填报历史查询: '填报历史查询',
  填报历史记录查询: '填报历史记录查询',
  'from 币种': 'from 币种',
  'to 币种': 'to 币种',
  期间范围: '期间范围',

};
