import Cookies from 'js-cookie';

/**
 * 获取 localStorage
 */
export const getLS = (name: string) => {
  if (!name) return;
  return JSON.parse(window.localStorage.getItem(name));
};

/**
 * 删除 localStorage
 */
export const removeLS = (name: string) => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

/**
 * 存储 localStorage
 */
export const setLS = (name: string, content: any) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};


/**
 * 清空登录缓存
 */
export const clearCache = () => {
  // 清cookie
  Cookies.set('t_c_p_r_token', '');
  Cookies.set('t_c_p_r_user', '');
  Cookies.set('t_c_p_r_menus', '');
  // 清 localStorage
  removeLS('t_c_p_r_token');
  removeLS('t_c_p_r_user');
  removeLS('t_c_p_r_menus');
  removeLS('user');
};

/**
 * 用户访问日志
 */
export const previewLog = (path: string) => {
  const userHistory: any[] = getLS('t_c_p_r_log');
  if (userHistory) {
    userHistory.unshift(path);
    userHistory.length = 5;
    setLS('t_c_p_r_log', userHistory);
  } else {
    setLS('t_c_p_r_log', []);
  }
};
